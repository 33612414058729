import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";

const GeneralTermsAndConditions: React.FC = () => {
  return (
    <>
      <SEO
        title={"General Terms and Conditions"}
        description={"General Terms and Conditions"}
      />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>FASTRRR INTERNATIONAL</h1>
          <h1>GENERAL TERMS AND CONDITIONS OF SERVICE</h1>
          <p>Last Updated: 1 August, 2021</p>
          <p>GENERAL INFORMATION</p>
          <ol>
            <li>
              <p>
                These General Terms and Conditions of Service are a legal
                agreement between you and FASTRRR International LLC pertaining
                to the sale, use and maintenance of the Company’s performance
                tracking device and related online performance tracking and
                performance analysis services on the Databay platform.
              </p>
            </li>
            <li>
              <p>Definitions:</p>
            </li>

            <p>
              <strong>Company/Service Provider</strong>: FASTRRR International
              LLC (registered in Hungary under the company registration number
              19-09-520671; registered seat at
              <a
                href="https://www.google.hu/maps/place/8200Veszpr%C3%A9m+Bolg%C3%A1r+Mih%C3%A1ly+utca+24.+"
                target="_blank"
                rel="noreferrer noopener"
              >
                {" "}
                24 Bolgár Mihály Str
              </a>
              ., 8200 Veszprém, Hungary; VAT-number: HU27034712; email:{" "}
              <a
                href="mailto:info@fastrrr.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                info@fastrrr.com
              </a>
              )
            </p>

            <p>
              <strong>Device</strong>: A performance tracking and performance
              analysis device manufactured and distributed by the Company.
            </p>

            <p>
              <strong>Databay/Platform</strong>: is an online performance
              analysis software service, which is owned and operated by the
              Company and is available in desktop view via an Internet browser
              on our Website or via our App.
            </p>

            <p>
              <strong>Product</strong>: the Device and the Platform together.
            </p>

            <p>
              <strong>Customer/User</strong>: is any person who places an order,
              pays for a Product on the Website - or even in person – and
              registers on the Databay platform.
            </p>

            <p>
              <strong>Visitor</strong>: is a person who browses the Website
              without registrating on it.
            </p>

            <p>
              <strong>Website</strong>: is a webpage to access the Databay
              platform provided by the Company at{" "}
              <a
                href="https://www.fastrrr.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://www.fastrrr.com/
              </a>
              .
            </p>

            <p>
              <strong>App</strong>: a web-based mobile application provided by
              the Company, which can be downloaded for free from the App Store
              or Google Play, whreby a registered User may access the Databay.
            </p>

            <li>
              <p>
                By completing a purchase or registering on our Platform you
                agree to be legally bound by and act in accordance with these
                Terms and Conditions and all other applicable provisions under
                Hungarian law. If you disagree with these Terms and Conditions
                and/or with any other of the applicable provisions, you are not
                permitted to, and agree not to, use our services. The legal
                agreement will be evidenced by your User Account information and
                any device and services purchased through our Platform or in
                person from our Company.
              </p>
            </li>

            <li>
              <p>
                Our Company reserves the right to revise these Terms and
                Conditions. We will notify our Users via the email address
                provided in their User Accounts 15 days prior to the changes
                made. The amended Terms and Conditions shall apply from the day
                of its posting on our Website and in our App. Your continued use
                of the Platform or Product following the posting of any changes
                to these Terms and Conditions constitutes your acceptance of
                those changes. 
              </p>
            </li>

            <li>
              <p>
                Should any provision of these Terms and Conditions be invalid or
                unenforceable, or later become so, this shall not affect the
                validity of the other provisions of these terms. Accordingly,
                should the Service Provider become aware of an invalid or
                unenforceable provision, the Service Provider shall replace it
                (in accordance with Section 1.2) with a provision as close as
                possible to the original objective of these Terms.
              </p>
            </li>
          </ol>

          <p>PURCHASE AND SUBSCIPTION POLICY</p>

          <ol>
            <li>
              <p>
                The Customer may choose from the following three devices:
                DinghyEdition, YachtEdition and CoachboatPRO (hereinafter
                referred to as ’Device’). You can find the description of the
                essential features of these Devices under the Product
                Information page on our Website.
              </p>
            </li>

            <li>
              <p>
                The Product includes a subscription to the Databay services. The
                available subsciption packages, the services included in a
                subscription package and their fees are described under the
                Subscription page on our Website. Please note that the content
                and services made available through Databay are subjects to
                change. The implemented changes will be posted on our Website as
                per section 4 under General Information and in your User
                Account.
              </p>
            </li>

            <li>
              <p>
                If the Customer chooses a free subscription package by
                purchasing the Product, only the data transmitted by the given
                Device will be available to him. Data storage as well as
                additional services (e.g. summaries, evaluations) will not be
                available. The list of services included in the subscription
                packages is included in the Fee Schedule.
              </p>
            </li>

            <li>
              <p>
                You can place an order in person, directly with one of our
                Company’s representatives or online, through our Website.
              </p>
            </li>

            <li>
              <p>
                When placing an order, the following personal information are
                required: name, e-mail address, billing and/or delivery address,
                contact information. You can find out more about the handling of
                our Customer’s personal information in our Privacy Policy.
              </p>
            </li>

            <li>
              <p>
                We will notify the Customer (via e-mail) about whether the order
                was successfully placed or not. The Company will confirm the
                Customer’s contract statement without delay by sending a letter
                to the email address provided by the User during the placement
                of the order. If for any reason the Customer does not receive a
                confirmation email within 24 hours, the Customer shall notify
                the Company immediately. The Company shall not be liable for any
                damages arising from the Customer failure to notify.
              </p>
            </li>

            <li>
              <p>
                The Customer acknowledges that it is only possible to correct
                data entry errors during the placement of the order before
                pressing the ‘Order’ button. If you notice such an error or
                omission after placing your order, please let us know
                immediately. The Company shall not be liable for any damages
                arising from the Customer’s failure to notify.
              </p>
            </li>

            <li>
              <p>
                After placing an order, you may select a means of delivery. You
                may collect the device in person or you may choose delivery by a
                third party delivery service. Collection in Person is free of
                charge. Otherwise, the delivery fee is added to the subscription
                fee when placing an order. It isn&apos;t necessary for the
                Service Provider to install the Product. The availability of a
                Device will be indicated on the Website, and the current
                delivery deadline depending on the availability, will be
                indicated in the order confirmation.
              </p>
            </li>

            <li>
              <p>
                The placement of the order can be cancelled at any time before
                pressing &quot;Next&quot;, without any legal consequences.
              </p>
            </li>

            <li>
              <p>
                Should you fail to complete your payment or should your payment
                fail for any reason, your launched order will be deleted. The
                Company shall not be liable for any damages related to such
                failure of payment.
              </p>
            </li>

            <li>
              <p>
                Subscription fees and delivery fees for the delivery service can
                be paid by PayPal. For the terms and conditions of online
                payment, please read the general terms and conditions of the
                third party service provider. The Company shall not be liable
                for any errors or outages during payment through these third
                party service providers.
              </p>
            </li>

            <li>
              <p>
                The User is entitled to cancel the subscription until the 30th
                day from the receipt of the Product. Otherwise, the subscription
                is final, so upon receipt of the subscription fee, the Service
                Provider will not refund it, including in the event that the
                User&apos;s User Account or access right is (for any reason)
                restricted or terminated.
              </p>
            </li>

            <li>
              <p>
                The minimum duration of the Subscription is one month. However,
                in the absence of termination, the subscription will be
                automatically renewed on the last day of the subscription
                period. The fee of the automatically renewed subscription is the
                same as the User&apos;s original subscription fee, regardless of
                any change in the fee for the subscription package in the
                meantime. The subsequent subscription fee will be charged the
                same way as the original subscription. The subscription will
                continue until the automatic renewal is terminated.
              </p>
            </li>

            <li>
              <p>
                You can cancel the automatic renewal of the subscription at any
                time at least 1 (one) day before the next renewal by clicking on
                the appropriate link in your User Account.
              </p>
            </li>

            <li>
              <p>
                The Service Provider issues the invoice of the subscription fee
                for the User with their name and address upon payment and sends
                it electronically to the email address provided by the User.
              </p>
            </li>

            <li>
              <p>
                The Company reserves the right to modify the content, fees and
                conditions of use of the services provided on the Platform from
                time to time, or even to terminate the Platform, or the
                provision of all or parts of the Platform’s services. Any
                changes implemented shall be posted in compliance with these
                Terms and Conditions.
              </p>
            </li>
          </ol>

          <p>REGISTRATION</p>

          <ol>
            <li>
              <p>
                Registring on Databay is free of charge. However, the range of
                services available on Databay depends on the Customer’s
                subscription package. You can register a Device on the Website
                under “Registration” or by filling out the relevant data fields
                in our App.
              </p>
            </li>

            <li>
              <p>
                A User can only create one User Account, but multiple Devices
                can be assigned to a single User Account. If a Customer
                purchases more than one Device at a time, or if the Customer
                purchases a new or additional Device(s) as a registered User,
                the new or additional Device(s) will automatically appear in the
                User&apos;s User Account.
              </p>
            </li>

            <li>
              <p>
                When registering, the following personal information are
                required: e-mail address, password. You can find out more about
                our handling of any personal information provided by our
                Customer in our Privacy Policy.
              </p>
            </li>

            <li>
              <p>
                The Company shall notify the User by e-mail about the success or
                failure of the Registration. The Company will confirm the
                User&apos;s contractual agreement without delay by sending a
                letter to the email address provided by the User during
                registration. If for any reason you do not receive a
                confirmation email within 24 hours, as the User, you are obliged
                to notify us, the Company immediately. The Company shall not be
                liable for any damages arising from the User&apos;s failure to
                notify us.
              </p>
            </li>

            <li>
              <p>
                When confirming the placement of order or the registration, the
                Service Provider verifies that the e-mail address provided is
                real. With an invalid email address, the Registration is invalid
                and unsuccessful. As the User, you are responsible for providing
                your own, valid email address during registration and for
                maintaining the email address until the termination of your User
                Account. The Company shall not be liable for any damages arising
                from an invalid or otherwise unsuccessful purchase or
                registration.
              </p>
            </li>

            <li>
              <p>
                By placing an order or registering, as the User, you warrant
                that you register with your real and true personal information
                and that you are a natural person of at least 16 years old and
                have the legal capacity to enter into this contract according to
                Hungarian law. Regsitering on or using our Platform without
                meeting these conditions, constitutes a breach of these general
                terms and conditions. By registering on Databay, as the User,
                you declare and warrant that you meet the conditions set forth
                in this section and are entitled to register on Databay.
              </p>
            </li>

            <li>
              <p>
                If there is a change in your personal information, as a User,
                you are obliged to change the information in your User Account.
                The User shall be solely responsible for any failure to do so or
                for any false, incomplete or misleading information in their
                User Account.
              </p>
            </li>

            <li>
              <p>
                By registering on Databay, as the User, you acknowledge and
                expressly consent to the Company automatically recording,
                storing and managing the personal information and performance
                data provided by the User on Databay or recorded by the Device
                in accordance with the provisions set out in our Privacy Policy.
              </p>
            </li>

            <li>
              <p>
                The Company reserves the right to verify the information
                provided by the User during registeration at any time. If the
                Company becomes aware that the data provided by the User is not
                true, or two (or more) accounts are registered or used by the
                same person without the prior approval of the Company, the
                Company is entitled to partially or completely restrict or
                suspend the access of the User concerned.
              </p>
            </li>

            <li>
              <p>
                The Service Provider reserves the right to reject the
                Registration of the User without any notice and to terminate the
                User’s User Account.
              </p>
            </li>

            <li>
              <p>
                The Service Provider is entitled to refuse a registration from
                an already excluded e-mail address or IP address, or if this new
                registration has already been submitted, to cancel the
                registration immediately, without prior notice and providing an
                explanation. The exercise of this right of the Service Provider
                is not limited by any subscription or any services purchased by
                the Customer.
              </p>
            </li>
          </ol>

          <p>TERMS OF USE</p>

          <ol>
            <li>
              <p>
                Considering that the condition for using our Platform is
                accepting the following documents as legally binding on the
                User; as the User, you hereby accept the terms of these General
                Terms, the purchase and subsciption policy, the privacy policy
                and the cookie policy and you warrant to use the Product in a
                manner consistent with these legal documents. If you do not
                agree with the provisions of these General Terms and policies,
                please do not use our Product.
              </p>
            </li>

            <li>
              <p>
                As the User, you are obliged to use the Device and the Platform
                as intended, and in compliance with the provisions of these
                General Terms and any legislation in force at any time.
              </p>
            </li>

            <li>
              <p>
                Users - and Visitors of the Website - may use or visit the
                Platform at their own risk.
              </p>
            </li>

            <li>
              <p>
                You can log in to Databay through the User Account on our
                Website or on our App.
              </p>
            </li>

            <li>
              <p>
                Any (personal) data tracked by the Device(s) - e.g. User
                performance data – registered on the Databay Platform, will be
                recorded in the Customer’s User Account. If the Customer does
                not register the Device on Databay, the data tracked by the
                Product will not be recorded as personal data, ie they will not
                be accessible and cannot be viewed by the User, and no our
                additional Services will not be available.
              </p>
            </li>

            <li>
              <p>
                The Company reserves the right to use the data measured in an
                anonymized or aggregated form for statistical purposes, product
                development and to improve user experience.
              </p>
            </li>

            <li>
              <p>
                The User may not use the Platform in a manner that interferes
                with the lawful use of the Platform by other Users, nor may the
                User use the Platform or the User Account to gain unauthorized
                access to any services, data, another User Account or the
                Company&apos;s server.
              </p>
            </li>

            <li>
              <p>
                The User may make a copy and print out the content of the
                Platform - its database - and its parts only for personal use
                only. You may not further use the content of the Platform&apos;s
                database for private purposes, whether on digital or physical
                media (in particular, but not limited to, reproducing,
                distributing, storing, downloading, marketing, reworking). Nor
                can private use indirectly serve the purpose of generating
                income or increasing income.
              </p>
            </li>

            <li>
              <p>
                The Service Provider is entitled to restrict the User&apos;s
                access to certain services provided on the Platform or to delete
                the User Account, if, in the Service Provider&apos;s opinion,
                the User uses the Platform in an abusive, violating or in grave
                breach of the provisions of these Genral Terms.
              </p>
            </li>
          </ol>

          <p>CANCELLATION AND REFUND POLICY</p>

          <ol>
            <li>
              <p>
                As the User, you may change your subscription package at any
                time through your User Account, which change will take effect on
                the day following the last day of your previous subscription
                period.
              </p>
            </li>

            <li>
              <p>
                You may initiate the cancellation of your subscription
                (hereinafter: Cancellation) at any time through your User
                Account or by e-mail. The Cancellation shall take effect on the
                day following the last day of your subscription period. With the
                Cancellation, the User Account is not terminated, but, in the
                future, you will only have access to the data and any analysis
                recorded during your active subscription period.
              </p>
            </li>

            <li>
              <p>
                The User Account is terminated upon Termination by User or
                exclusion by the Service Provider.
              </p>
            </li>

            <li>
              <p>
                You may initiate the termination of your User Account at any
                time through your User Account or by e-mail (hereinafter:
                Termination by User). The Service Provider shall close the
                User&apos;s Account on the 15th day from the date of receipt of
                the request for Termination by User (hereinafter: Termination
                Period), provided that the User has no debts to the Service
                Provider. Please send the your request to service@fastrrr.com. A
                request to terminate a User Account also qualifies as a
                Subscription Cancellation.
              </p>
            </li>

            <li>
              <p>
                Please note that deleting the App does not constitute – neither
                technically, nor legally - a cancellation of the Subscription or
                the termination of the User Account, so the User’s subscription
                and the User Account will be continued.
              </p>
            </li>

            <li>
              <p>
                After deleting the User Account, the Service Provider is not
                obliged, but shall be entitled to keep the User&apos;s data
                anonymously.
              </p>
            </li>
          </ol>

          <p>PROVISIONS PERTAINING TO OUR APP</p>

          <ol>
            <li>
              <p>
                In this chapter, you will find special provisions regarding the
                conditions of your use of our App, insofar as they differ from
                the conditions of use of our Website.
              </p>
            </li>

            <li>
              <p>
                The App can be downloaded for free from the App Store or Google
                Play app stores. Given that the Company uses a third party
                service provider to make the App available to its Customers and
                that the Company cannot and does not assume any responsibility
                for the actions or omissions of these third party service
                providers, please read the terms of service of the App Store
                and/or Google Play, respectively. By downloading the App, you
                expressly agree to accept the terms of service these thrid party
                service providers. If you do not agree to those terms of service
                providers, please do not download our App. You can access the
                Databay Platform on our Website directly via your web browser.
              </p>
            </li>

            <li>
              <p>The App can only be used by registered Users.</p>
            </li>

            <li>
              <p>
                The Company grants a non-exclusive, non-transferable right to
                install and use one copy of the Application per User device from
                anywhere in the world for a limited duration (from the time of
                the downloading to deleting the App) in order to track and
                evaluate the Customer’s performance.
              </p>
            </li>

            <li>
              <p>
                For the sake of clarity, the Company authorizes the use of the
                App, but does not transfer its ownership and copyright, and all
                rights relevant tot he servive not expressly transferred by
                these Terms and Conditions are reserved by the Company. As the
                Customer, you are entitled to use the App in accordance with
                your access rights - through your own User Account.
              </p>
            </li>

            <li>
              <p>
                As the User, you may not make an electronic or analog copy of
                the App, in whole or in part, nor may you grant or display
                access to it to unauthorized third parties.
              </p>
            </li>

            <li>
              <p>
                As the User, you are not entitled to reproduce the App as a
                whole, or a part(s) of it. The required software replication
                involves installing the App on the your device&apos;s mass
                storage and loading the App into your device&apos;s main memory.
                As the User, you are not entitled to modify the App or create a
                derivative work in whole or in part.
              </p>
            </li>

            <li>
              <p>
                Your right to use the previous version(s) of our App expires
                when the update or new version is installed. As the User, you
                are not entitled to use the previous version(s) on another
                computer system or to allow the use of or access to the previous
                version(s) to an unauthorized third party after the update or
                the installation of the new version.
              </p>
            </li>

            <li>
              <p>
                As the User, you are not entitled to (re)sell, transfer or
                sublease, sublicense or otherwise make the App or the User’s
                right to use available to any Unauthorized Third Party. You may
                also not rent, lease or make the App available to unauthorized
                third parties through hosting or streaming facilities. You may
                not make any improvements the App, even if there is a delay by
                the Company in resolving the errors indicated by the User to the
                Company.
              </p>
            </li>

            <li>
              <p>
                As the User, you are not allowed to translate the App’s program
                code into other forms code, to decipher other types of software
                production stages, or to analyze the internal structure of the
                Application (hereinafter separately or together: Decryption).
              </p>
            </li>
          </ol>

          <p>LIABILITY</p>

          <ol>
            <li>
              <p>
                Your use of our Product (both the Device and the Platform) is
                entirely voluntary and at your own risk.
              </p>
            </li>

            <li>
              <p>
                The Company declares and the User acknowledges purchasing the
                Product and continuously accepts by using our Product, that the
                Device is the result of Company&apos;s own technical
                development, which has not been the subject of an official
                quality assurance or certification procedure and does not have
                any qualification, certification or license issued by a third
                party or public authority. Accordingly, the Company will only be
                liable for damages arising from the intended use of a device of
                such a nature, properties and features, as set forth below.
              </p>
            </li>

            <li>
              <p>
                In the event of a defect in the Device, the Customer is entitled
                to make a warranty claim against the Company for two years from
                the date of purchase, within the scope of the Company&apos;s
                warranty obligations. The Company also undertakes a one-year
                guarantee from the date of purchase regarding the Device. The
                warranty period does not include the period while the Customer
                is unable to use the Device, provided that the Customer has
                immediately notified the Company of the defect. The Customer is
                obliged to report the defect immediately after its discovery,
                but no later than within two months from the discovery of the
                defect. The Company shall not be liable for any damages arising
                from the delay or failure to notify. The Customer may request
                the repair or replacement of the Device, unless it is impossible
                to fulfill the claim of the Customer or it would result in
                disproportionately high additional costs for the Company
                compared to fulfilling the claim in another manner. The cost of
                returning the Device will be borne by the Customer, but the
                Customer will not have to pay an additional delivery fee of the
                repaired or new Device. If the repair or replacement has not
                been requested or could not be requested, the Customer may make
                a claim for price reduction, or in the event of a serious
                defect, may also cancel the contract. The Customer can switch
                from the original warranty claim to another, however, the cost
                of switching shall be borne by the Customer, unless it was
                justified or caused by the Company. The Company is released from
                the warranty claim, if it proves that
              </p>
            </li>

            <ol>
              <li>
                <p>
                  the Customer was aware of the defect at the time of concluding
                  the contract, or
                </p>
              </li>
              <li>
                <p>
                  the Customer has used the Device improperly, the Customer
                  disregarded the contents of the user’s guide; improperly
                  stored or handled, intentionally or negligently damaged,
                  disassembled, or failed to perform necessary maintenance; or
                </p>
              </li>
              <li>
                <p>
                  if the defect was caused by the elements or a natural
                  disaster.
                </p>
              </li>
            </ol>
            <li>
              <p>
                The Product requires proper power supply to operate. To access
                the Platform, you need Internet connection or mobile phone data
                connection (hereinafter collectively: Data Connection), as well
                as sufficient amount of free storage space on the device used by
                the User. As the User, you are obliged to ensure that the
                technical devices, storage, browser or any application necessary
                for the operation of the Device and access to the Platform, as
                well as the Data Connection are available to the User. The
                Company shall not be liable for any operational interruptions
                that may occur in connection with the use of these technical
                devices, their storage, or the absence, malfunction or any
                limitation of Data Connections during the use of the Device or
                the Platform.
              </p>
            </li>

            <li>
              <p>
                If the Company detects an error or defect in the Product, the
                Product Description, the Purchase and Subscription Policy on the
                Website or in the App, the Company reserves the right to correct
                it immediately and inform the Customer thereof immediately. If
                you notice any errors or omissions on the Website or in the App,
                please notify us immediately. The Company shall not be liable
                for any damages arising from failure to notify after the
                Customer has become aware of such an error or defect.
              </p>
            </li>

            <li>
              <p>
                The User acknowledges and accepts that the Company provides the
                Platform on an “as is” basis with the functions, conditions and
                quality available at the time of accessing the Platform.
                Accordingly, the User acknowledges and accepts that occasional
                outages and downtime may occur with all online services. During
                the loss or interruption of the availability or the
                accessibility of the Platform, as the User, you may temporarily
                be unable to access the Platform or your User Account. In order
                to minimize the possibility of data loss, the Service Provider
                will do everything - technically and commercially - expected of
                it. In addition, the Company excludes its liability for risks
                and damages arising from any data loss, interruptions and
                outages.
              </p>
            </li>

            <li>
              <p>
                The Company continuously monitors changes in legislation
                affecting the operation of the Product. The Company also
                undertakes to continuously develop, improve and optimize the
                Device and the Platform due to the complex nature of IT systems
                and the dynamically changing market environment. The Service
                Provider makes the software updates and configuration changes
                (hereinafter: Update) in accordance with legislation in force at
                any time, which are prescribed for the IT system and apply to
                the already existing functions, available to the User, ie
                downloadable via the Internet. If the User downloads the App,
                the installation of the displayed Updates is the duty and
                responsibility of the User; the Service Provider excludes any
                liability for any damages arising from failure to do so. If the
                Service Provider develops an essentially necessary Update, the
                Service Provider shall perform the Updates automatically; the
                Service Provider excludes any liability for any damages arising
                from the automatic Updates.
              </p>
            </li>

            <li>
              <p>
                The Company is the author and sole owner of the database
                available on the Platform, which qualifies as a collection of
                works. The Service Provider may use the whole or a part of the
                Platform exclusively for the Users only in accordance with the
                purpose of the Platform. Only the Company is authorized to use
                the database and to grant third parties permission to use the
                database. The Company does not allow the use of all or any part
                of the database produced by it on any Website or in any other
                way for any business purpose.
              </p>
            </li>

            <li>
              <p>
                The Service Provider shall not be liable for any delay, delivery
                error, or any other loss or damage arising from data traffic
                between the communication networks, the GPS-technology used, and
                any equipment connected to these, or from any services used by
                the Product provided by third party service providers (such as
                weather forecasts).We are not responsible for any delays,
                delivery failures, or any other loss or damage resulting from
                the transfer of data over communications networks and
                facilities, including the internet, and you acknowledge any
                services throughout this Platform may be subject to limitations,
                delays and other problems inherent in the use of such
                communications facilities.
              </p>
            </li>

            <li>
              <p>
                If the User notices an interruption, error or malfunction, the
                User is obliged to notify the Service Provider without delay
                after its discovery. The User is responsible for the damage
                resulting from the delay of the communication. The costs related
                to the correction of the error shall be borne by the Service
                Provider. If the failure of the User&apos;s maintenance
                obligation, ie the download of the Updates, may have contributed
                to the failure of the App, the User shall bear the costs
                incurred while correcting the error, ie performing the Service
                Provider&apos;s warranty obligation, and the associated damages
                in proportion to its impact.
              </p>
            </li>

            <li>
              <p>
                The Service Provider excludes any liability for any damages
                arising from inaccurate, incorrect or false data provided by the
                Users when purchasing a Device or using the Platform. The
                Platform automatically ensures the identification and the
                possibility to correct any errors made by the User during the
                recording of the data before finalizing the personal information
                provided data, either during the Registration or during the
                saving of the subsequent changes made to the personal
                information.
              </p>
            </li>

            <li>
              <p>
                The Service Provider excludes any liability for any and all
                damages arising from deleting any content uploaded by the User
                to the Platform due to the User&apos;s violation of these
                General Terms or any relevant legislation, or in order to
                prevent any such violations.
              </p>
            </li>

            <li>
              <p>
                The User is responsible and warrants the lawful and proper use
                of the Platform. If the User causes damage to the Service
                Provider while using the Platform - especially in connection
                with the violation of these General Terms, the User shall be
                fully liable for such damages. The Service Provider completely
                excludes anys liability for the damage caused by the User to
                third parties using the Platform and the User is obliged to
                fully reimburse the damages caused by the User.
              </p>
            </li>
          </ol>

          <p>UPLOADED CONTENT</p>

          <ol>
            <li>
              <p>
                As the User, you may publish visual content on the Platform. The
                User who publishes the content is fully responsible for the
                content published by that User on the Platform, including for
                the damages caused by the content or otherwise incurred in
                connection therewith. The Company is not responsible and does
                not assume any responsibility for these contents or their
                lawfulness, and the User shall be liabe instead of the Company
                before a third party or any authority.
              </p>
            </li>

            <li>
              <p>
                It is forbidden to display content on the Platform and to
                specifically upload images or if possible texts that:
              </p>
            </li>

            <ol>
              <li>
                <p>is against the law</p>
              </li>

              <li>
                <p>commits a crime,</p>
              </li>

              <li>
                <p>it is against good morals</p>
              </li>

              <li>
                <p>
                  infringes the rights or legitimate interests of others, in
                  particular their copyright, or good reputation, or
                </p>
              </li>

              <li>
                <p>contains misleading information.</p>
              </li>
            </ol>

            <li>
              <p>
                The User has the right to notify the Company if there is any
                content on the Platform that violates the law, is misleading or
                otherwise infringes the rights or legitimate interests of
                others, or for other reasons may be necessary to remove. The
                Company will, if justified, take action to remove the content
                concerned within 8 days of the notification.
              </p>
            </li>

            <li>
              <p>
                The User agrees to grant the Company the right to use any
                pictures, photos and/or text (eg product photo, sailing photos)
                uploaded to the Platform simultaneously with the upload, free of
                charge for an indefinite period of time, free of territorial
                restrictions, transferable to third parties. The Company is
                entitled to use, reproduce, alter or transfer the uploaded
                content, in whole or in part, or to use it in any other known
                way, without any territorial or temporal restrictions, within
                the framework of its right of use. The User expressly waives any
                due fees at the same time as uploading the content by accepting
                these Terms and Conditions.
              </p>
            </li>

            <li>
              <p>
                The Company is entitled, but not obliged, to archive any Content
                uploaded by the User to or on the Platform, unless required by
                law.
              </p>
            </li>
          </ol>

          <p>CLIENT SERVICE</p>

          <ol>
            <li>
              <p>
                If you have any questions or comments during or about the use of
                the Platform, please write to us at service@fastrrr.com. The
                Service Provider will start processing your request within 48
                hours of receiving the request and will respond to it no later
                than within 30 days.
              </p>
            </li>

            <li>
              <p>
                In connection with the use of the Product, the User and the
                Service Provider shall notify each other electronically. In the
                absence of confirmation, the notification sent by or to the User
                and/or the Service Provider shall be deemed to have been
                delivered one day after its sending. The User and the Service
                Provider separately but mutually acknowledge that the e-mails
                sent by them and their content are binding on them.
              </p>
            </li>

            <li>
              <p>
                Users based in Hungary may launch a consumer complaint related
                to the Device and the Platform, under the Hungarian Consumer
                Protection Act (Act CLV of 1997) and initiate the proceedings of
                the conciliation body
                (https://www.bekeltetes.hu/index.php?id=testuletek) at their
                place of residence. Concerning electronic advertisements, the
                proceedings of the communications authority may be initiated.
                Contact details of the Office of the National Media and
                Communications Authority; mailing address: 1525 Budapest, Pf.,
                75; info@nmhh.hu.
              </p>
            </li>

            <li>
              <p>
                If the User wishes to conduct and settle a consumer dispute
                online, the User can do so via the online dispute resolution
                platform at https://ec.europa.eu/odr, where the Service
                Provider&apos;s e-mail address is{" "}
                <a
                  href="mailto:service@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  service@fastrrr.com
                </a>
                .
              </p>
            </li>

            <li>
              <p>
                The User and the Service Provider shall endeavor in good faith
                to resolve any legal disputes related to this contract in an
                amicable manner.
              </p>
            </li>
          </ol>

          <p>FINAL PROVISIONS</p>

          <ol>
            <li>
              <p>
                The Service Provider reserves the right to transfer the
                manufacturing and/pr the distribution of the Device, and/or the
                ownership, operation or maintenance of the Platform to a third
                party in the event of a liquidation, sale, merger, bankruptcy,
                provided that such third party accepts these Terms and
                Conditions as binding on it.
              </p>
            </li>

            <li>
              <p>
                In the event of a discrepancy between the Hungarian and English
                versions of these General Terms, the text of the Hungarian
                provision shall prevail.
              </p>
            </li>

            <li>
              <p>
                In matters not regulated by these General Terms, the relevant
                provisions of Hungarian law, in particular the provisions of Act
                V of 2013 on the Civil Code, shall apply.
              </p>
            </li>
          </ol>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default GeneralTermsAndConditions;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  ol ol li {
    list-style-type: lower-latin;
  }
`;
